import { Component, OnInit } from '@angular/core';
import { Account, EciPrincipalService } from '@shared-components/shopsys-commons-ui';
import { CountryService } from 'src/helpers/CountryService';
import { HomeService, TimeSeries, TimeSeriesResponse } from './home.service';
import { CountryManagementService } from '../master-data/components/country-management/country-management.service';

/**
 * A simple app-specific component.
 */
@Component({
    selector: 'vltc-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

    account: Account;
    country: string;
    countryId: string;
    timeframes: string[];
    selectedTimeframe: string;
    timeSeriesResponse: TimeSeriesResponse;
    timeSeriesArray: TimeSeries[];
    gaugeValue: number;
    maxGaugeValue: number;
    minGaugeValue: number;
    totalCalculationLogs: number;
    newCalculationLogs: number;
    standardDeliveryDays: number;
    masterArticleJobs: number;
    running = 0;
    pending = 0;
    global = 0;
    individual = 0;

    constructor(
        private principalService: EciPrincipalService,
        private countryService: CountryService,
        private countryManagementService: CountryManagementService,
        private homeService: HomeService,
    ) {
        this.countryService.getCountry.subscribe(c => {
            this.country = c.name;
            this.countryId = c.id;
        });
        this.timeframes = [
            '1H',
            '6H',
            '12H',
            '1D',
            '1W',
            '4W',
            '6W',
        ];
        this.selectedTimeframe = this.timeframes[0];
    }

    ngOnInit() {
        this.account = this.principalService.getIdentity();
        this.homeService.getTimeSeries(this.countryId, this.selectedTimeframe).subscribe(response => {
            this.timeSeriesResponse = response;
            this.timeSeriesArray = this.timeSeriesResponse.timeSeries;
            this.gaugeValue = this.timeSeriesResponse.averageCalculationTime;
            this.totalCalculationLogs = this.timeSeriesResponse.totalCalculationLogs;
        });
        this.homeService.getNewCalculationLogs(this.countryId).subscribe(response => {
            this.newCalculationLogs = response;
        });
        this.countryManagementService.getCountry().subscribe(country => this.standardDeliveryDays = country.standardDeliveryDays);
        this.homeService.getVltJobs(this.countryId).subscribe(vltJobs => {
            vltJobs.forEach(vltJob => {
                if (vltJob.status === 'PENDING') {
                    this.pending++;
                }
                if (vltJob.status === 'RUNNING' && vltJob.enabled === true) {
                    this.running++;
                }
            });
        });
        this.homeService.getMasterArticleJobs(this.countryId).subscribe(masterArticleJobs => {
            this.masterArticleJobs = masterArticleJobs.length;
        });
        this.homeService.getNumberOfMaintained(this.countryId, false).subscribe(global => {
            this.global = global;
        });
        this.homeService.getNumberOfMaintained(this.countryId, true).subscribe(individual => {
            this.individual = individual;
        });
    }

    isAuthenticated(): boolean {
        return this.principalService.isAuthenticated();
    }

    onValueChanged($event) {
        this.homeService.getTimeSeries(this.countryId, $event.value).subscribe(response => {
            this.timeSeriesResponse = response;
            this.timeSeriesArray = this.timeSeriesResponse.timeSeries;
            this.gaugeValue = this.timeSeriesResponse.averageCalculationTime;
            this.maxGaugeValue = this.timeSeriesResponse.maxCalculationTime;
            this.minGaugeValue = this.timeSeriesResponse.minCalculationTime;
            this.totalCalculationLogs = this.timeSeriesResponse.totalCalculationLogs;
        });
    }
}
